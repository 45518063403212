export const LEGACY_MERCHANTS = [
  "adaysmarch",
  "artilleriet",
  "bagarenochkocken",
  "blugiallo",
  "catjam",
  "craft",
  "demoripndip",
  "demotest",
  "geins",
  "ginatricot",
  "hna",
  "horsestuff",
  "horsestuff2",
  "horsestuff3",
  "idealofsweden",
  "johaug_v2",
  "johaug_v2_qa",
  "karitraa",
  "karitraa_v2",
  "karitraa_v2_qa",
  "kitchenlab",
  "krauta",
  "lisayang",
  "maxgaming",
  "merchant_id",
  "newport",
  "nielstest",
  "nordicspectra",
  "officedepot",
  "rains",
  "roseborn",
  "skistar",
  "spendrups",
  "spendrupsv2",
  "staples.intershop",
  "staples_intershop",
  "svenskttenn",
  "synsam",
  "temp",
  "twisttango",
  "zoo",
];
