import { useContext, useEffect } from "react";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";
import { useMerchantId } from "./useMerchantId";
import { Merchant } from "src/api/types";
import { LEGACY_MERCHANTS } from "../../../constants/legacyMerchants";

/**
 * useMerchant is a React hook that provides a convenient way to access the
 * MerchantContext.
 */
export function useMerchant(): {
  merchant: Merchant | null;
  merchantOptions: Merchant[];
  merchantId: string;
  loading: boolean;
} {
  const { merchantId } = useMerchantId();
  const authorizedMerchantsContext = useContext(AuthorizedMerchantsContext);

  useEffect(() => {
    if (
      !LEGACY_MERCHANTS.includes(merchantId) &&
      window.location.hostname !== "localhost" &&
      merchantId !== "testtenant"
    ) {
      // Route to no code portal at app.depict.ai
      window.location.href = `https://app.depict.ai/`;
    }
  }, [merchantId]);

  if (!authorizedMerchantsContext) {
    throw new Error("No AuthorizedMerchantsContext found");
  }

  const { authorizedMerchants, loading } = authorizedMerchantsContext;

  if (!authorizedMerchants && !loading) {
    throw new Error("No authorizedMerchants found");
  }

  if (!authorizedMerchants) {
    return {
      merchant: null,
      merchantOptions: [],
      merchantId,
      loading,
    };
  }

  return {
    merchant:
      authorizedMerchants.find((merchant) => merchant.id === merchantId) ||
      null,
    merchantOptions: authorizedMerchants,
    merchantId,
    loading,
  };
}

export default useMerchant;
